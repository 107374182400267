import React from "react"
import { Helmet } from "react-helmet"
import TemplatePage from "../../components/TemplatePage"
import { colors } from "../../styles/globals"

export default function Pcs({ location }) {
  return (
    <TemplatePage
      location={location}
      title="P.C.S."
      additionalClassName="templateFullContainerFaqScreen"
    >
      {/* <TabelLinkuri items={items} />
       */}
      <Helmet>
        <title>PCS - Gazmir</title>
        <meta
          name="description"
          content="Afla P.C.S. ul zilnic sau lunar ce determina pretul final al facturilor de gaze naturale. "
        />
        <meta
          name="keywords"
          content="PCS Gazmir,Gazmir PCS,P.C.S. Gazmir,Gazmir P.C.S.,Putere calorifica superioara gazmir,gazmir Putere calorifica superioara,Putera calorifica superioara gazmir,gazmir Putera calorifica superioara"
        />
      </Helmet>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: 32,
        }}
      >
        <iframe
          width="100%"
          height="400"
          frameBorder="0"
          scrolling="yes"
          src="https://docs.google.com/spreadsheets/d/1CREsixdWuJJg89EVpQRz95x6yJ_Nimz6LdTXOF4JZoo/htmlembed"
          allowFullScreen=""
        ></iframe>
        <a
          target="_blank"
          // className=""
          style={{
            color: colors.main,
            // textDecoration: "none",
            alignSelf: "flex-start",
          }}
          href="https://drive.google.com/open?id=1CREsixdWuJJg89EVpQRz95x6yJ_Nimz6LdTXOF4JZoo"
        >
          Pentru a vizualiza Puterea calorifica superioara pe zile, apasati aici
        </a>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignSelf: "stretch",
            marginTop: 40,
          }}
        >
          <strong>Puterea calorifica superioara lunara:</strong>
          <iframe
            width="100%"
            height="400"
            frameBorder="0"
            scrolling="yes"
            src="https://docs.google.com/spreadsheets/d/1KQPjpiPI77qj-fA1PuEUZqEyLjrm3f3kd4114pgX9eg/preview?usp=drivesdk"
            allowFullScreen=""
          ></iframe>
          <a
            target="_blank"
            // className=""
            style={{
              color: colors.main,
              // textDecoration: "none",
              alignSelf: "flex-start",
            }}
            href="https://docs.google.com/spreadsheets/d/1KQPjpiPI77qj-fA1PuEUZqEyLjrm3f3kd4114pgX9eg/preview?usp=drivesdk"
          >
            Pentru a vizualiza Puterea calorifica superioara pe zile, apasati
            aici
          </a>
        </div>
        {/* <strong>Puterea calorifica superioara lunara:</strong>
      [google-drive-embed
      url="https://docs.google.com/spreadsheets/d/1KQPjpiPI77qj-fA1PuEUZqEyLjrm3f3kd4114pgX9eg/preview?usp=drivesdk"
      title="PCS-lunar"
      icon="https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.spreadsheet"
      width="100%" height="400" style="embed"] */}
      </div>
    </TemplatePage>
  )
}
